import React, { useEffect, useState} from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';
import SectionContainer from '../SectionContainer';

import css from './SectionPartA.module.css';
import BRANDLOGOS from './img/brands.png';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import { Button } from '../../../../components';
import SLIDER1 from './img/sl_1.png';
import SLIDER2 from './img/sl_2.png';
import SLIDER3 from './img/sl_3.png';
import SLIDER4 from './img/sl_4.png';
import SLIDER5 from './img/sl_5.png';
import { FormattedMessage } from 'react-intl';

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionPartA = props => {

  const {isEsianForm,isMobileLayout, isUserLoggedIn } = props;

  if(isEsianForm) {
    return (
      <>
        <div className={css.brandImages}>
          <img src={BRANDLOGOS} alt='Brand Logos' width={'80%'} />
        </div>
      </>
    );
  }

  
  const onAddService = (value) => {
    
    if (isUserLoggedIn){
      //window.location.replace(value);
      history.pushState(null, '', value);
      window.location.href=value;
    }
    else {
      history.pushState(null, '', '/esian-signup');
      window.location.href='/esian-signup';
    }
    
  }    
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % 5);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide + 4) % 5);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 7000); // Auto slide every 3 seconds

    return () => clearInterval(interval);
  }, [currentSlide]);


  return ( 
    <>
    
    <div className={css.brandImages}>
      <img src={BRANDLOGOS} alt='Brand Logos' width={'80%'} />
    </div>
    {isMobileLayout === false ?<div className={css.esianSectiopn}>
        <h4 className={css.landingSubTItle}><FormattedMessage id="SectionPartA.subTitle" /></h4>
        <div className="slider1">

      <div className="slide1-container">
        <div className={currentSlide === 0 ? 'slide1 active' : 'slide1'}>
          <div className={css.sliderSection}>
            <div className={css.sliderImage}>
              <img src={SLIDER1}/>
            </div>
            <div className={css.sliderContent}>
              <h3 className={css.makeDifferent}>
              <FormattedMessage id="SectionPartA.heading" />         
              </h3>
              <p>
              <FormattedMessage id="SectionPartA.content" />
              </p>
              <Button className={css.buttonSlider}onClick={() => { onAddService("/l/new") }}>
              <FormattedMessage id="SectionPartA.BecomingEsianButton" /></Button>
            </div>
          </div>
        </div>
        <div className={currentSlide === 1 ? 'slide1 active' : 'slide1'}>
        <div className={css.sliderSection}>
            <div className={css.sliderImage}>
              <img src={SLIDER2}/>
            </div>
            <div className={css.sliderContent}>
              <h3 className={css.makeDifferent}>
                <FormattedMessage id="SectionPartA.SlideTextHead2" />
              </h3>
              <p>
              <FormattedMessage id="SectionPartA.SlideText2" />
              </p>
              <Button className={css.buttonSlider}onClick={() => { onAddService("/l/new") }}>
              <FormattedMessage id="SectionPartA.BecomingEsianButton" /></Button>
            </div>
          </div>
        </div>
        <div className={currentSlide === 2 ? 'slide1 active' : 'slide1'}>
        <div className={css.sliderSection}>
            <div className={css.sliderImage}>
              <img src={SLIDER3}/>
            </div>
            <div className={css.sliderContent}>
              <h3 className={css.makeDifferent}>
              <FormattedMessage id="SectionPartA.SlideTextHead3" />     
              </h3>
              <p>
              <FormattedMessage id="SectionPartA.SlideText3" />
              </p>
              <Button className={css.buttonSlider}onClick={() => { onAddService("/l/new") }}>
              <FormattedMessage id="SectionPartA.BecomingEsianButton" /></Button>
            </div>
          </div>
        </div>
        <div className={currentSlide === 3 ? 'slide active' : 'slide1'}>
        <div className={css.sliderSection}>
            <div className={css.sliderImage}>
              <img src={SLIDER4}/>
            </div>
            <div className={css.sliderContent}>
              <h3 className={css.makeDifferent}>
                <FormattedMessage id="SectionPartA.SlideTextHead4" />
              </h3>
              <p>
                <FormattedMessage id="SectionPartA.SlideText4" />
              </p>
              <Button className={css.buttonSlider}onClick={() => { onAddService("/l/new") }}>
              <FormattedMessage id="SectionPartA.BecomingEsianButton" /></Button>
            </div>
          </div>
        </div>
        <div className={currentSlide === 4 ? 'slide1 active' : 'slide1'}>
          <div className={css.sliderSection}>
            <div className={css.sliderImage}>
              <img src={SLIDER5}/>
            </div>
            <div className={css.sliderContent}>
              <h3 className={css.makeDifferent}>
              <FormattedMessage id="SectionPartA.SlideTextHead5" />     
              </h3>
              <p>
              <FormattedMessage id="SectionPartA.SlideText5" />
              </p>
              <Button className={css.buttonSlider}onClick={() => { onAddService("/l/new") }}>
              <FormattedMessage id="SectionPartA.BecomingEsianButton" /></Button>
            </div>
          </div>
        </div>
      </div>
      <div className={css.buttonControls}>
      <button className="button1" onClick={prevSlide}>&#10094;</button>
      <button className="button1" onClick={nextSlide}>&#10095;</button>
      </div>
    </div>


      </div>:''}
      
      </>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionPartA.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionPartA.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionPartA;
